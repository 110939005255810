<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <!-- Header -->
    <main-header />
    <!-- Content -->
    <section v-if="currentArtist && currentArtist.length > 0">
      <div class="container">
        <!-- Breadcrumb -->
        <div class="_flex _center">
          <nav aria-label="breadcrumb" style="height:40px!important">
            <ol class="breadcrumb  bg-transparent tiny">
              <li class="breadcrumb-item"><a href="#">Accueil</a></li>
              <li class="breadcrumb-item"><a href="#">Artistes</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ fullname }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--  -->
      <div class="container my-10 px-10-md px-20-lg">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3 d-flex flex-column align-items-center text-center">
            <img :src="currentArtist[0].data_user.data_picture ? currentArtist[0].data_user.data_picture.src : getEmptyUserPicture" alt="" class="artwork_artist_img"/>
            <div class="_flex _center">
              <a href="" class="_flex">
                <i class="icon-follow mr-2"></i>
                <span class="underline">Suivre</span>
              </a>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-9">
            <h3 class="bold text-capitalize">{{ fullname }}</h3>
            <div class="medium mb-4 _flex subheading">
              <i class="icon-here mr-2"></i> <span class="text-capitalize">{{ currentArtist[0].data_user.country }}</span>
              <span class="mx-2" style="color:#999;font-weight:300;">|</span>
              Né(e) le : {{ currentArtist[0].data_user.birthday ? (new Date(currentArtist[0].data_user.birthday)).toLocaleDateString("FR") : "Non divulgué !"}}
            </div>
            <div v-html="currentArtist[0].data_user.apropos"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="container">
        <div class="title-separator-grand"></div>
        <div class="px-5-md px-8-lg my-10">
          <ul class="list-unstyled h2 bold _flex _center">
            <li class=""><u>Oeuvres de l'artiste</u></li>
          </ul>
          <div class="my-10">
            <!-- 1 -->
            <div class="card-columns">
              <artwork-card v-for="artwork in currentArtist" :artwork="artwork" :key="artwork.artwork_id"/>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </section>
    <section v-else>
      <div class="d-flex w-100 flex-column align-items-center my-20">
        <img src="../assets/images/no_result_search.svg" alt="" style="height:180px;width:180px;object-fit:contain"/>
        <br />
        <h4 style="max-width:400px;text-align:center;font-weight:600">Aucune information pertinente trouvée de cet(cette) artiste !</h4>
      </div>
    </section>
    <!--  -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data: () => ({
    loading: false,
    currentArtist: null,
  }),
  computed: {
    ...mapGetters([
        "getEmptyUserPicture",
        "getEmptyArtworkPicture",
    ]),
    fullname() {
      return `${this.currentArtist[0].data_user.last_name || ""} ${this.currentArtist[0].data_user.middle_name || ""} ${this.currentArtist[0].data_user.first_name || ""}`;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetAllData",
        "axiosGetOneData",
    ]),
  },
  mounted() {},
  created() {
    this.loading = true;
    this.axiosGetOneData({table: "retrieveArtworksByArtist", table_id: this.$route.params.id})
        .then( response => {
          if(response.status === 200){
            this.currentArtist = response.data.data
            this.$toast.success(response.data.message, {
              icon: "fas fa-2x fa-check-circle",
            });
          }else {
            this.$toast.error(response.data.message, {
              icon: "fas fa-2x fa-times-circle",
            });
          }
          this.loading = false;
        })
        .catch()
  },
  beforeDestroy() {},
};
</script>

<style></style>
